import React, { useState } from 'react';
import './HeroSection.css';
import aikraLogo from '../assets/aikra-logo.png'; // Your custom logo path
import xLogo from '../assets/x-logo.png'; // X logo path
import dexscreenerLogo from '../assets/dexscreener-logo.png'; // Dexscreener logo path
import telegramLogo from '../assets/telegram-logo.svg.webp'; // Telegram logo path

const HeroSection = () => {
  const [contractVisible, setContractVisible] = useState(false);
  const contractAddress = 'CDDfRgh3pqGM4uRdqiikcfAncMFzCVqYM5YQrXrNpump '; // Replace with your actual contract address

  const handleReveal = () => {
    setContractVisible(!contractVisible);
  };

  return (
    <section className="hero-section">
      <div className="big-title">WELCOME TO AIKRA</div> {/* Big title added */}
      <div className="hero-content">
        <img src={aikraLogo} alt="AIKRA Logo" className="hero-logo" />
        <p className="hero-subtext">The Awakening Has Begun...</p>
        <div className="social-icons">
          <a href="https://x.com/aikra_sol" target="_blank" rel="noopener noreferrer">
            <img src={xLogo} alt="X Logo" className="social-icon" />
          </a>
          <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
            <img src={dexscreenerLogo} alt="Dexscreener Logo" className="social-icon" />
          </a>
          <a href="https://t.me/+n8LLh45k6tU1NjEx" target="_blank" rel="noopener noreferrer">
            <img src={telegramLogo} alt="Telegram Logo" className="social-icon" />
          </a>
        </div>
        <button className="reveal-button" onClick={handleReveal}>
          {contractVisible ? 'Hide' : 'Reveal'} Contract Address
        </button>
        {contractVisible && (
          <div className="contract-address-container">
            <p className="contract-address">{contractAddress}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroSection;


