import React, { useState } from 'react';
import './DecryptionChallenge.css';

const DecryptionChallenge = () => {
  const [input, setInput] = useState('');
  const [message, setMessage] = useState('Solve the code: QFUBMV');
  const [isSolved, setIsSolved] = useState(false);

  const correctAnswer = 'SECRET'; // The correct answer for decryption

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {
    if (input.toUpperCase() === correctAnswer) {
      setMessage('Congratulations! You have decrypted the code.');
      setIsSolved(true);
    } else {
      setMessage('Incorrect. Try again or think like a hacker!');
    }
    setInput('');
  };

  return (
    <section className="decryption-challenge-section">
      <div className="decryption-challenge">
        <div className="challenge-content">
          <p className="challenge-message">{message}</p>
          {!isSolved && (
            <div className="input-area">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Enter your answer..."
              />
              <button onClick={handleSubmit}>Submit</button>
            </div>
          )}
          {isSolved && (
            <div className="reward-message">
              <p>You've unlocked an exclusive AIKRA reward!.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default DecryptionChallenge;
