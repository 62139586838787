import React, { useState } from 'react';
import './OracleSection.css';

const OracleSection = () => {
  const prophecies = [
    'The path forward is shrouded in code.',
    'AI convergence awaits the daring.',
    'Unlock the cipher, change your fate.',
    'Signals echo through the network.',
    'AI will illuminate the unknown.'
  ];
  const [prophecy, setProphecy] = useState('Click to Reveal the Prophecy');

  const revealProphecy = () => {
    const randomIndex = Math.floor(Math.random() * prophecies.length);
    setProphecy(prophecies[randomIndex]);
  };

  return (
    <section className="oracle-section">
      <h2>The AI Oracle</h2>
      <div className="prophecy-box" onClick={revealProphecy}>
        {prophecy}
      </div>
    </section>
  );
};

export default OracleSection;
