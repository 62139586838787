import React, { useState } from 'react';
import './HiddenNode.css';

const HiddenNode = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <div className="hidden-node" onClick={handleClick}>
      {active ? (
        <p className="node-revealed">Node Activated: Data Stream Unlocked.</p>
      ) : (
        <p className="node-hidden">Hidden Node Detected. Click to Activate.</p>
      )}
    </div>
  );
};

export default HiddenNode;
