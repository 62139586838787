import React, { useState, useEffect } from 'react';
import './EncryptedStream.css';

const EncryptedStream = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const messages = [
      'AIKRA SYSTEMS ONLINE...',
      'Initiating Protocol 27-A...',
      'Awaiting Decryption Key...',
      'Unraveling the Neural Core...',
      'Phase Shift Detected...'
    ];
    let index = 0;
    const interval = setInterval(() => {
      setMessage(messages[index]);
      index = (index + 1) % messages.length;
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="encrypted-stream">
      <div className="stream-container">
        <p className="stream-text">{message}</p>
      </div>
    </section>
  );
};

export default EncryptedStream;
