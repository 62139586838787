import React, { useState } from 'react';
import './HackerTerminal.css';

const HackerTerminal = () => {
  const [command, setCommand] = useState('');
  const [output, setOutput] = useState([]);

  const handleCommand = () => {
    if (command.trim() === '') return;

    // Pre-defined command responses
    const responses = {
      help: 'Available commands: help, secret, promo, clear',
      secret: 'You have unlocked a hidden AIKRA secret.',
      promo: 'AIKRA Special Promo: Buy now and receive an exclusive bonus!',
      clear: 'Terminal cleared.',
    };

    // Handle command
    const response = responses[command.toLowerCase()] || 'Unknown command. Type "help" for a list of commands.';
    const newOutput = [...output, `> ${command}`, response];
    
    if (command.toLowerCase() === 'clear') {
      setOutput([]); // Clear the output
    } else {
      setOutput(newOutput); // Update output with new response
    }

    setCommand(''); // Clear input
  };

  return (
    <section className="hacker-terminal-section">
      <div className="terminal-output">
        {output.map((line, index) => (
          <div key={index} className="terminal-line">{line}</div>
        ))}
      </div>
      <div className="terminal-input">
        <span className="prompt-symbol">$</span>
        <input
          type="text"
          value={command}
          onChange={(e) => setCommand(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleCommand()}
          placeholder="Type a command..."
        />
      </div>
    </section>
  );
};

export default HackerTerminal;
