import React, { useEffect, useRef } from 'react';
import './Background.css';

const Background = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const columns = Math.floor(canvas.width / 20);
    const drops = Array(columns).fill(1);

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#00ffcc'; // Bright green for a hacker vibe
      ctx.font = '15px monospace';

      drops.forEach((y, index) => {
        const text = String.fromCharCode(0x30a0 + Math.random() * 96); // Random character
        const x = index * 20;
        ctx.fillText(text, x, y * 20);

        if (y * 20 > canvas.height && Math.random() > 0.975) {
          drops[index] = 0; // Reset drop to the top
        }
        drops[index]++;
      });
    };

    const interval = setInterval(draw, 50);

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return <canvas ref={canvasRef} className="background-canvas"></canvas>;
};

export default Background;
