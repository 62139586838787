import React, { useState } from 'react';
import './HiddenMessage.css';

const HiddenMessage = () => {
  const [revealed, setRevealed] = useState(false);

  const handleMouseEnter = () => {
    setRevealed(true);
  };

  const handleMouseLeave = () => {
    setRevealed(false);
  };

  return (
    <div
      className="hidden-message-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <p className={`hidden-text ${revealed ? 'revealed' : ''}`}>
        <span className="code-symbol">&lt;Secret&gt;</span> The network knows your name.
      </p>
    </div>
  );
};

export default HiddenMessage;
