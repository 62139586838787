import React, { useState } from 'react';
import './AIChatbot.css';

const AIChatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    if (input.trim() === '') return;

    const userMessage = { text: input, sender: 'user' };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);

    // Generate a response after a short delay
    setTimeout(() => {
      const aiResponse = generateResponse(input);
      setMessages(prevMessages => [...prevMessages, { text: aiResponse, sender: 'ai' }]);
    }, 1000);

    setInput('');
  };

  const generateResponse = (inputText) => {
    const lowerCaseInput = inputText.toLowerCase();

    // Example responses (can be expanded or connected to a more sophisticated system)
    const responses = {
      greetings: [
        "Hello, traveler of the network.",
        "Greetings, user. How may I assist?",
        "Welcome. Your presence has been detected.",
      ],
      buying: [
        "Seizing the opportunity can be the difference between profit and regret.",
        "The best time to act is now. You know this.",
        "AIKRA awaits your move. The window may not stay open for long.",
      ],
      generic: [
        "The system is processing...",
        "I am here. What do you wish to explore?",
        "I have seen things you wouldn't believe...",
      ],
    };

    // Basic keyword matching for response selection
    if (lowerCaseInput.includes('hello') || lowerCaseInput.includes('hi')) {
      return responses.greetings[Math.floor(Math.random() * responses.greetings.length)];
    } else if (lowerCaseInput.includes('buy')) {
      return responses.buying[Math.floor(Math.random() * responses.buying.length)];
    } else {
      return responses.generic[Math.floor(Math.random() * responses.generic.length)];
    }
  };

  return (
    <div className="ai-chatbot">
      <div className="chat-window">
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.sender}`}>
              <span>{msg.text}</span>
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask me anything..."
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default AIChatbot;
