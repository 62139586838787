import React from 'react';
import './App.css';
import HeroSection from './components/HeroSection';
import EncryptedStream from './components/EncryptedStream';
import OracleSection from './components/OracleSection';
import HackerTerminal from './components/HackerTerminal';
import HiddenNode from './components/HiddenNode';
import Background from './components/Background';
import HiddenMessage from './components/HiddenMessage';
import AIChatbot from './components/AIChatbot'; // Import the AIChatbot
import DecryptionChallenge from './components/DecryptionChallenge'; // New Decryption Challenge

function App() {
  return (
    <div className="App">
      <Background />
      <div className="background-overlay"></div> {/* Optional overlay for readability */}
      <HeroSection />
      <DecryptionChallenge />
      <EncryptedStream />
      <HackerTerminal /> {/* HackerTerminal as a section */}
      <OracleSection />
      <HiddenNode />
      <HiddenMessage />
      <AIChatbot /> {/* AIChatbot positioned as needed */}
    </div>
  );
}

export default App;

